/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import Navigation from "./Navigation";
import { makeStyles } from "@fluentui/react-components";
import { Route, Routes } from "react-router-dom";
import Chat from "../pages/Chat";
import Projects from "../pages/Projects";
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";
import { useAuth0 } from "@auth0/auth0-react";
import LoginPage  from "./login/LoginPage";

const useStyles = makeStyles({
  root: {
    maxHeight: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
});

const App = (props) => {
  const styles = useStyles();
  const { isAuthenticated, user, loginWithRedirect, login, logout } = useAuth0();

  useEffect(() => {
    const handleAuthentication = async () => {
      if (window.location.hash) {
        const { getTokenSilently } = useAuth0();
        await getTokenSilently();
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };

    handleAuthentication();
  }, []);

  return (
    <div className={styles.root}>
      {isAuthenticated ? (
        <>
          <Navigation />
          <main style={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<Chat />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </main>
        </>
      ) : (
        <LoginPage/>
      )}
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
