/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { TabList, Tab, makeStyles, Tooltip, Image } from "@fluentui/react-components";
import { ChatFilled, DocumentFolderFilled, PersonFilled, SettingsFilled } from "@fluentui/react-icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px",
    rowGap: "20px",
    height: "10vh",
    backgroundColor: "#00464d",
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    rowGap: "20px",
  },
});

const Navigation = (props) => {
  const styles = useStyles();

  const navItems = [
    {
      key: "chat",
      name: "Chat",
      icon: <ChatFilled />,
      route: "/",
    },
    // {
    //     key: 'projects',
    //     name: 'Projects',
    //     icon: <DocumentFolderFilled />,
    //     route: '/projects',
    // },
    // {
    //     key: 'profile',
    //     name: 'Profile',
    //     icon: <PersonFilled />,
    //     route: '/profile',
    // },
    // {
    //     key: 'settings',
    //     name: 'Settings',
    //     icon: <SettingsFilled />,
    //     route: '/settings',
    // },
  ];

  return (
    <div className={styles.root}>
      <TabList defaultSelectedValue="Chat">
        {navItems.map((item) => (
          <Link to={item.route} key={item.key}>
            <Tooltip content={item.name} position="right">
              <Tab key={item.key} icon={item.icon} value={item.name} />
            </Tooltip>
          </Link>
        ))}
      </TabList>
      <Image width="40" height="40" src="assets/logo-filled.png" alt="Company Logo" />
    </div>
  );
};

export default Navigation;
