import * as React from "react";
import { makeStyles, Image } from "@fluentui/react-components";
import LoginButton  from "./LoginButton";

const useStyles = makeStyles({
    conatiner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#00464d",
    }});

const LoginPage = () => {

    const classes = useStyles();

    return (
        <div className={classes.conatiner}>
            <Image src="../../../../assets/icon-128.png" alt="Logo" />
            <LoginButton />
        </div>
    );
};

export default LoginPage;