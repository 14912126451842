import React, { useState } from 'react';
import { makeStyles, Field, Textarea, Button, Spinner } from '@fluentui/react-components';
import { SendFilled } from '@fluentui/react-icons';
import Message from '../components/chat/message';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
    position: 'relative',
  },
  messageContainer: {
    marginBottom: '20px',
    flex: '1',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'bottom',
    position: 'sticky',
    bottom: '0',
    left: '0',
    width: '100vw',
    margin: '10px',
    marginBottom: '0px',
    backgroundColor: 'white',
    minHeight: '10vh'
  },
  input: {
    marginRight: '10px',
    border: '1px solid lightgrey',
    borderRadius: '5px',
    width: '80vw',
    minHeight: '10vh'
  },
  spinner: {  
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button: { 
    color: '#00464d',
    transform: 'rotate(-45deg)'
  },
});

const Chat = () => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userQuestion, setUserQuestion] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendClick = async () => {
    if (inputValue.trim() !== '') {
      Word.run(async (context) => {
        try {
          setLoading(true);
          setUserQuestion(inputValue);
          setResponse('');
          setInputValue('');
          const response = await fetch('http://localhost:8080/api/chat/completions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-ScholarAI-API-Key': 't5UMIO99UqWbJG5dLTiiuhLZs5tgFLm8t8stkCyoOCqx4Y5UkzY6txRWyDRRSh0B'
            },
            body: JSON.stringify({
              model: 'gpt-4',
              messages: [{ role: 'user', content: inputValue }],
              stream: true
            })
          });

          const reader = response.body.getReader();
          const range = context.document.getSelection();
          let result = '';
          
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            result = new TextDecoder().decode(value);
            let parsedResult;
            try {
              const results = result.split('\n');
              for (const res of results) {
              parsedResult = JSON.parse(res);
              const content = parsedResult.choices[0].delta.content;
              setLoading(false);
              setResponse((prev) => prev + content);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
              // Handle the error here, e.g. set a default value for parsedResult
              continue;
            }
            // Insert the content into the document
            range.insertText(content, Word.InsertLocation.after);
          }
          await context.sync();
        } catch (error) {
          console.error('Error:', error);
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.messageContainer}>
        {response && <Message user="ScholarAI" message={response} userQuestion={userQuestion}/>}
        {loading && <Spinner className={classes.spinner}labelPosition="below" label="Generating for you..." />}
      </div>
      <div className={classes.inputContainer}>
        <Field>
          <Textarea 
            value={inputValue}
            size="medium"
            onChange={handleInputChange}
            className={classes.input}
            placeholder="Ask ScholarAI..." />
        </Field>
        <Button className={classes.button} appearance="transparent" onClick={handleSendClick} size="large" icon={<SendFilled />} />
      </div>
    </div>
  );
};

export default Chat;
