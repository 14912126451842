import React from 'react';
import { Tooltip, Button, makeStyles } from '@fluentui/react-components';
import { AddSquareRegular } from '@fluentui/react-icons';


const useStyles = makeStyles({
    button: {
        color: '#00464d',
    },
});

const AddToDocumentButton = ({ message }) => {
    const classes = useStyles();
    const handleClick = () => {
        // Add the message content to the Word document
        // Replace this with your actual implementation

        // Example code:
        Office.context.document.setSelectedDataAsync(message, { coercionType: Office.CoercionType.Text });
    };

    return (
        <Tooltip content="Add to Document" position="below">
            <Button className={classes.button} appearance="transparent" onClick={handleClick} size="large" icon={<AddSquareRegular />} />
        </Tooltip>
    );
};

export default AddToDocumentButton;