/* eslint-disable no-undef */
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider } from "@fluentui/react-components";
import { MemoryRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { lightTheme, darkTheme } from "./theme";

/* global document, Office, module, require */
const title = "ScholarAI";

const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_DOMAIN;

/* Render application after Office initializes */
Office.onReady((info) => {
  if (info.host === Office.HostType.Word) {
    root.render(
      <Router>
        <Auth0Provider domain={domain} clientId={clientId} authorizationParams={{ redirect_uri: window.location.origin }}>
          <FluentProvider theme={lightTheme} lightTheme={lightTheme} darkTheme={darkTheme}>
            <App title={title} />
          </FluentProvider>
        </Auth0Provider>
      </Router>
    );
  }
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}
