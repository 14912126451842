/* eslint-disable no-undef */
import * as React from "react";
import { makeStyles, Button } from "@fluentui/react-components";

const useStyles = makeStyles({
    button: {
            columnGap: "15px",
            display: "flex",
            minWidth: "min-content",
            color: "#00464d",
            padding: "10px",
            margin: "10px",
          },
    });

const LoginButton = () => {

    const classes = useStyles();

    const openAuthDialog = () => {
        Office.context.ui.displayDialogAsync(
            window.location.origin + "/auth-dialog.html", 
            { height: 50, width: 50 },
            (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                const dialog = result.value;
                console.log('Dialog created: ', dialog);
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
                console.log('Dialog message event added');
                } else {
                console.error('Failed to open dialog:', result.error.message);
                }
            }
            );
        };

    const processMessage = (message) => {
        const authResult = JSON.parse(message.message);
        console.log('Received auth result:', authResult);
        if (authResult.status === 'success') {
        console.log('Authentication succeeded', authResult);
        // Send auth result back to parent
        Office.context.ui.messageParent(JSON.stringify(authResult));

        // Handle successful authentication (e.g., store the token, update UI, etc.)
        
        } else {
        // eslint-disable-next-line no-undef
        console.error('Authentication failed', authResult.error);
        // Handle authentication failure
        }
    };

    return (
        <div>
            <Button className={classes.button} onClick={openAuthDialog}>Log In</Button>
        </div>
    
    );
};

export default LoginButton;
