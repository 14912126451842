import * as React from "react";
import { makeStyles, tokens, Caption1 } from '@fluentui/react-components';
import Markdown from 'react-markdown'
import AddToDocumentButton from './add_to_document';

const useStyles = makeStyles({
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '8px'
    },
    profileIcon: {
        marginRight: tokens.spacingVerticalS,
        padding: '8px',
        borderRadius: '50%',
        color: "#00464d",
        display: 'flex',
        fontWeight: 'bold',
    },
    messageBubble: {
        display: 'flex',
        borderRadius: '8px',
        paddingLeft: "8px",
        paddingRight: "8px",
        boxShadow: '0px 2px 4px rgba(176, 193, 195)' // Add dropshadow here
    },
    messageContent: {
        flex: 1,
    },
});

const Message = ({ user, message }) => {
    const classes = useStyles();
    const isFromScholarAI = user === 'scholarai';
    const bubbleColor = isFromScholarAI ? 'primary' : 'secondary';

    return (
        <div>
            <div className={classes.messageContainer}>
                <div className={classes.profileIcon}>{user} :</div>
                <div className={classes.messageBubble}>
                    <Markdown className={classes.messageContent}>{message}</Markdown>
                </div>
                <AddToDocumentButton message={message} />
            </div>
        </div>
    );
};

export default Message;