import { createDarkTheme, createLightTheme } from "@fluentui/react-components";

const scholarai = {
  10: "#000504",
  20: "#001E1B",
  30: "#00302C",
  40: "#003E38",
  50: "#084B45",
  60: "#1B5851",
  70: "#2C655E",
  80: "#3C736C",
  90: "#4C8079",
  100: "#5D8E87",
  110: "#6F9C95",
  120: "#81A9A3",
  130: "#94B7B2",
  140: "#A7C5C1",
  150: "#BBD3CF",
  160: "#D0E1DE",
};

const lightTheme = {
  ...createLightTheme(scholarai),
};

const darkTheme = {
  ...createDarkTheme(scholarai),
};

darkTheme.colorBrandForeground1 = scholarai[110];
darkTheme.colorBrandForeground2 = scholarai[120];

export { lightTheme, darkTheme };
